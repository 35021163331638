<template>
  <div>
    <div :style="styleBarChart" class="bar-chart" ref="chart">

    </div>

  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  props: {
    isrotate: {
      type: Boolean,
      default: false
    },
    styleBarChart: {
      type: Object,
      default: () => {
        return {
          width: '700px',
          height: '390px',
        };
      },
    },
    xtype: {
      type: String,
      default: 'category',
    },
    ytype: {
      type: String,
      default: 'value',
    },
    // 滚动条限度
    zoomNum: {
      type: Number,
      default: 100,
    },
    // 是否添加数据滚动条
    zoomShow: { type: Boolean, default: false },
    maxData: {
      type: Number,
      default: 300,
    },
    coloreBarChart: {
      type: Array,
      default: () => {
        return ['#00BFFF', '#73FFFF'];
      },
    },
    rowData: {
      type: Array,
      default: () => {
        return []
      },
    },
    chartStylr: {
      type: Object,
      default: () => {
        return {
          unit: '单位：人次',
          barWidth: 5,
        };
      },
    },
    //打开折叠显示 显示几个字(0是关闭折叠显示)
    OpenParamsNameNumber: {
      type: Number,
      default: () => {
        return 6;
      },
    },
    gridData: {
      type: Object,
      default: () => {
        return {
          top: '5%',
          bottom: '30%',
          left: '12%',
          right: '10%',
        };
      },
    },
  },
  data() {
    return {
      chartInst: null,
      option: {},
      setTime: null,
    };
  },
  methods: {
    instantiation() {
      let that = this;

      this.chartInst = echarts.init(this.$refs.chart);
      this.option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            animation: false,
            label: {
              backgroundColor: '#505765',
            },
          },
        },
        grid: this.gridData,
        xAxis: {
          type: this.xtype,
          data: [],
          axisLine: {
            lineStyle: {
              color: '#BFEBFF',
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            interval: 0,
            rotate: this.isrotate ? 30 : 0,

          },
        },
        yAxis: {
          type: this.ytype,
          name: this.chartStylr.unit,
          // max: this.maxData,
          nameTextStyle: {
            fontSize: 18,
            padding: [0, 0, 0, 0], // 四个数字分别为上右下左与原位置距离
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
                lineStyle:{
                    type:'solid'
                }
          },
          axisLine: {
            lineStyle: {
              color: '#BFEBFF',
            },
          },
          axisLabel: {
            fontSize: 14,
            interval: 0,
            formatter: function(value, index) {
              if (value / 10000 > 0) {
                return parseInt(value / 10000) + 'w';
              }
              return value;
            }
          },
          splitArea: {
            show: false,
            areaStyle: {
              color: ['rgba(255,255,255,0.1)', 'rgba(238,238,238,0)'],
            },
          },
        },
        // 数据过多时添加滚动条
        dataZoom: [
          {
            type: 'slider',
            show: this.zoomShow, //flase直接隐藏图形
            xAxisIndex: [0],
            left: '9%', //滚动条靠左侧的百分比
            bottom: -23,
            start: 0, //滚动条的起始位置
            end: this.zoomNum, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
          },
        ],
        series: [
          {
            data: [],
            avoidLabelOverlap: false,
            type: 'bar',
            barWidth: 15,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: this.coloreBarChart[0] },
                { offset: 1, color: this.coloreBarChart[1] },
              ]),

            },
          },
        ],
      };
    },
    rowDataChart() {
      let name = [];
      let value = [];
      this.rowData.forEach((val) => {

        name.push(val['name']);
        value.push(val['val']);
      });
      this.option.xAxis.data = name;
      this.option.series[0].data = value;
      this.chartInst.setOption(this.option, true);
    },
    // tooltipShuffling() {
    //   //
    //   clearInterval(this.setTime);
    //   let count = 0;
    //   this.setTime = setInterval(() => {
    //     if (count == this.rowData.length) {
    //       count = 0;
    //     }
    //     this.chartInst.dispatchAction({
    //       type: 'showTip',
    //       seriesIndex: 0,
    //       dataIndex: count,
    //     });
    //     count++;
    //   }, 3000);
    // },
  },
  mounted() {
    this.instantiation();
    this.rowDataChart();

    // this.tooltipShuffling();
  },
  beforeDestroy() {
    clearInterval(this.setTime);
    echarts.init(this.$refs.chart).dispose();
  },
  watch: {
    rowData: {
      handler(newV, oldV) {
        this.instantiation();
        this.rowDataChart();
        // this.tooltipShuffling();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped></style>
