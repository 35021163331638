<template>
  <div id="index" ref="appRef">
    <div id="china-map"></div>
    <!--        <div class="right_box"></div> -->
  </div>
</template>

<script>
  import {
    fetchOrderMap
  } from '@/services/fetch.js';
  export default {

    data() {
      return {
        selectName: '',
        reqData: [],
        chinaGeoCoordMap: {
          '新疆': [86.9023, 41.148],
          '西藏': [87.8695, 31.6846],
          '内蒙': [110.5977, 41.3408],
          '青海': [95.2402, 35.4199],
          '四川': [102.9199, 30.1904],
          '黑龙': [128.1445, 46.7156],
          '甘肃': [102.7129, 38.166],
          '云南': [101.0652, 24.6807],
          '广西': [108.7813, 23.6426],
          '湖南': [111.5332, 27.3779],
          '陕西': [108.5996, 33.7396],
          '广东': [113.8668, 22.8076],
          '吉林': [126.1746, 43.5938],
          '河北': [115.4004, 38.1688],
          '湖北': [112.2363, 30.8572],
          '贵州': [106.6113, 26.6385],
          '山东': [118.2402, 36.2307],
          '江西': [115.7156, 27.99],
          '河南': [113.0668, 33.8818],
          '辽宁': [123.0438, 41.0889],
          '山西': [112.4121, 36.6611],
          '安徽': [117.2461, 31.0361],
          '福建': [118.3008, 25.9277],
          '浙江': [120.498, 29.0918],
          '江苏': [119.8586, 32.915],
          '重庆': [107.7539, 29.8904],
          '宁夏': [105.9961, 37.1096],
          '海南': [109.9512, 19.2041],
          '台湾': [120.8254, 23.5986],
          '北京': [116.4551, 40.2539],
          '天津': [117.4219, 39.4189],
          '上海': [121.4648, 31.2891],
          '香港': [114.6178, 22.3242],
          '澳门': [113.5547, 21.6484],
        },
        lineData: []
      };
    },

    mounted() {

      fetchOrderMap().then(res => {
        this.reqData = res
        let datas = this.reqData.results.lineList
        datas.forEach(item => {
          let from = item.fromName.split(" ")[0].substring("string", 2);
          let end = item.endName.split(" ")[0].substring("string", 2);
          let tomapdata = {
            blat: this.chinaGeoCoordMap[from],
            elon: this.chinaGeoCoordMap[end],
            bcitysim: from,
            ecitysim: end
          }
          this.lineData.push(tomapdata)



        })
        //中国地图
        this.china_map(this.lineData);
        //
      })


    },
    beforeDestroy() {

    },
    methods: {

      //中国地图
      china_map() {
        let mapChart = this.$echarts.init(document.getElementById("china-map")); //图表初始化，china-map是绑定的元素
        window.onresize = mapChart.resize; //如果容器变大小，自适应从新构图
        let series = []; //存放循环配置项
        let res = []; //存放射线的起始点和结束点位置
        let province = []; //存放有射线的省的名字，以此来拿到对应省份的坐标
        //提前存好的所有省份坐标，用于后面根据名字拿到对应的左边
        //后台给的数据模拟

        let that = this
        //循环拿到处理好的数据
        for (var i = 0; i < this.lineData.length; i++) {
          province.push(this.lineData[i].bcitysim); //存进去每个省的名字
          province.push(this.lineData[i].ecitysim); //存进去每个省的名字
          res.push({
            fromName: this.lineData[i].bcitysim, //发射的省名，保存用于弹框显示
            toName: this.lineData[i].ecitysim, //接收的省名，保存用于弹框显示
            coords: [
              this.lineData[i].blat, //发射
              this.lineData[i].elon, //接收
            ],
            // count: this.lineData[i].val, //数据
          });
        }
        let index_data = new Set(province); //把省的名字去重
        let data_res = []; //定义一个新的变量存放省的坐标

        //注意这里一定要用name和value的形式。不是这个格式的散点图显示不出来
        index_data.forEach((item) => {
          data_res.push({
            name: item, //每个省的名字
            value: this.chinaGeoCoordMap[item], //每个省的坐标
          });
        });
        //循环往series内添加配置项
        series.push({
            //射线效果图层
            type: "lines", //类型：射线
            zlevel: 1, //类似图层效果
            effect: {
              show: true, //是否显示图标
              symbol: "arrow", //箭头图标
              symbolSize: 5, //图标大小
              trailLength: 0.02, //特效尾迹长度[0,1]值越大，尾迹越长重
            },
            label: {
              show: true,
            },
            lineStyle: {
              color: "#fff",
              normal: {
                color: "#00e62e",
                width: 1, //尾迹线条宽度
                opacity: 0.5, //尾迹线条透明度
                curveness: 0.1, //尾迹线条曲直度
              },
            },
            //提示框信息
            tooltip: {
              formatter: function(param) {
                return (
                  param.data.fromName +
                  ">" +
                  param.data.toName
                  // +"<br>数量：" +
                  // param.data.count
                );
              },
            },
            data: res, //拿到射线的起始点和结束点
          },

          {
            type: "map",
            mapType: "china",
            zlevel: 1,
            roam: true,
            geoIndex: 0,
            tooltip: {
              show: true,
            },
            itemStyle: {
              areaColor: "#00196d",
              borderColor: "#0a53e9",
            },
            emphasis: {
              show: true,
              label: {
                normal: {
                  show: true, // 是否显示对应地名
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
              itemStyle: {
                areaColor: "#00196d",
                borderColor: "#0a53e9",
              },
            },
          }
        );

        //配置
        let option = {
          //title可要可不要

          // title: {
          //   text: "查查的地图",
          //   textStyle: {
          //     color: "#ffffff",
          //   },
          // },
          legend: {
            show: true,
            selected: {},
            x: "left",
            orient: "vertical",
            textStyle: {
              color: "white",
            },
            data: [],
          },
          //鼠标移上去的弹框
          tooltip: {
            trigger: "item",
            show: true,
          },
          //geo：这是重点
          geo: {
            map: "china", //中国地图
            zoom: 1.2, //缩放倍数
            roam: false, //是否允许缩放和拖拽地图
            label: {
              normal: {
                show: true, // 是否显示省份名字，现在是隐藏的状态，因为和散点图的名字重叠了。如果需要就true
                textStyle: {
                  //名字的样式
                  color: "#fff",
                },
              },
              emphasis: {
                show: true,
              },
            },
            regions: [{
              name: "南海诸岛",
              itemStyle: {
                // 隐藏地图
                color: "#fff",
                normal: {
                  opacity: 1, // 为 0 时不绘制该图形

                }
              },
              label: {
                show: false // 隐藏文字
              }
            }],
            //地图样式
            itemStyle: {
              normal: {
                borderColor: "#13C5EE", //地图边框颜色
                borderWidth: "3", //地图边框粗细
                areaColor: "#0C52B9", //地图背景色
              },
              //省份地图阴影
              emphasis: {
                areaColor: null,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 20,
                borderWidth: 0,
                shadowColor: "#fff",
              },
            },
          },
          series: series, //图表配置
        };

        mapChart.setOption(option); //生成图表
      },
    },
  };
</script>

<style lang="less" scoped>
  //中国地图
  #china-map {
    height: 660px;
    width: 100%;
  }

  .right_box {
    width: 89px;
    height: 124px;
    position: absolute;
    bottom: 18px;
    right: 49px;
    background: url(../img/right_box.png) no-repeat;
    background-size: 100% 100%;
  }
</style>












<!--

    .tjfx .box:nth-child(2) .icon {
    background: url('../img/sj.png');
  }

  .tjfx .box:nth-child(3) .icon {
    // width: 66px;
    // height: 46px;
    background: url('../img/cl.png');
  }

  .tjfx .box:nth-child(4) .icon {
    // width: 51px;
    // height: 46px;
    background: url('../img/fx.png');
  }

  .tjfx .box:nth-child(5) .icon {
    // width: 45px;
    // height: 47px;
    background: url('../img/bj.png');
  }

  .tjfx .box:nth-child(6) .icon {
    // width: 51px;
    // height: 39px;
    background: url('../img/pj.png');
  }

  .tjfx .box:nth-child(7) .icon {
    // width: 83px;
    // height: 42px;
    background: url('../img/kd.png');
  }

  .tjfx .box:nth-child(8) .icon {
    // width: 52px;
    // height: 52px;
    background: url('../img/fy.png');
  }
 -->
