<template>
    <div>
        <div class="header">
           
            <div class="middle">
                宏达运智慧监测平台
               
            </div>
            
        </div>
       
    </div>
</template>

<script>
import zhhyLeft from '../components/zhhyLeft.vue'
export default {
    name: 'LargeScreenTopheader',
    components : {
        zhhyLeft
    },
    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style scoped>
.header{
    width: 100%;
    height: 92px;
    background: url('../img/header-bg.png') ;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
   
}
/* .left{
    width: 160px;
    height: 38px;
} */
.middle{
    color: #01C4F7;
    text-align: center;
    font-size: 38px;
    width: 100%;
    box-sizing: border-box;
    padding-top:15px ;
}
</style>