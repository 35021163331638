<template>
    <div>
        <div ref="echart" :style="{ width: width + 'px', height: height + 'px' }"></div>

    </div>
</template>

<script>
export default {
    name: 'LargeScreenListOne',
    props: {
        width: {
            type: String,
            default: "100"
        },
        height: {
            type: String,
            default: "100"
        },
        Xdata: {
            type: Array,
            default: () => {
                return []
            }
        },
        Ydata: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {

        };
    },

    mounted() {
        this.placeCharts();
    },

    methods: {
        placeCharts() {
            let myChart = this.$echarts.init(this.$refs.echart);
            let option = {
                dataset: {
                    source: []
                },
                grid: { containLabel: true },
                xAxis: { name: 'amount' },
                yAxis: { type: 'category' },
                visualMap: {
                    orient: 'horizontal',
                    left: 'center',
                    min: 10,
                    max: 100,
                    text: ['High Score', 'Low Score'],
                    // Map the score column to color
                    dimension: 0,
                    inRange: {
                        color: ['#65B581', '#FFCE34', '#FD665F']
                    }
                },
                series: [
                    {
                        type: 'bar',
                        encode: {
                            // Map the "amount" column to X axis.
                            x: 'amount',
                            // Map the "product" column to Y axis
                            y: 'product'
                        }
                    }
                ]
            }
            myChart.setOption(option);
        }
    }
};
</script>

<style  scoped></style>
