<template>
    <div>
        <div class="content" :class="classname" :style="{ width: width + 'px', height: height + 'px' }">
            <div class="title" v-if="istitle">
                <span>{{ title }}</span>

            </div>
            <div class="button" v-if="isButton">
                <div :class="i == 0 ? 'active' : ''" v-for="(item, i) in buttonList" @click="changeData(item)" ref="button">
                    {{ item }}
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LargeScreenContentBox',
    props: {
        istitle: {
            type: Boolean,
            default: true
        },
        width: {
            type: String,
            default: "100"
        },
        height: {
            type: String,
            default: "100"
        },
        title: {
            type: String,
            default: "月度增长趋势"
        },
        buttonList: {
            type: Array,
            default: () => {
                return ['司机', '车辆']
            }
        },
        isButton: {
            type: Boolean,
            default: true
        },
        classname: {
          type: String,
          default:''
        }
    },
    data() {
        return {

        };
    },

    mounted() {

        // this.$refs.button['0'].classList.value = 'active'
    },

    methods: {
        changeData(val) {
            this.$emit('buttonclick', val);
            let index;
            for (var i = 0; i < this.buttonList.length; i++) {
                if (this.buttonList[i] == val) {
                    index = i
                }
                this.$refs.button[i].classList.value = ''
            }

            this.$refs.button[index].classList.value = 'active'

        }
    },
};
</script>

<style  scoped>
.content {
    background: url('../img/bg_center.png') no-repeat;
    background-size: 100% 100%;
        /* box-shadow: inset 2px 2px 16px 2px #227092;
        border-radius: 20px;
    background-attachment:fixed; */

}
.china_map{
  background-attachment: unset;
}
.modal_left_1{
  background: url('../img/left1.png') no-repeat;
  background-size: 100% 100%;
}

.modal_left_2{
  background: url('../img/left2.png') no-repeat;
  background-size: 100% 100% ;
}


.modal_left_3{
  background: url('../img/left2.png') no-repeat;
  background-size: 100% 100% ;
}

.active {
    background-color: #00FFFF;
    color: black !important;
}

.title {
    position: absolute;
    top: -8px;
    left: 40px;
    width: 316px;
    height: 38px;
    background: url('../img/title-bg.png');
    font-family: SourceHanSansCN-;
    font-size: 18px;
    color: #fff;
    line-height: 38px;
    box-sizing: border-box;
    padding-left: 30px;
    display: flex;
    justify-content: space-between;
}

.button {
    position: absolute;
    top: -5px;
    right: 45px;
    z-index: 222;
    height: 30px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.button div {
    box-sizing: border-box;
    font-size: 12px;
    margin-left: 10px;
    width: 70px;
    border: 1px solid#00FFFF;
    text-align: center;
    line-height: 30px;
    color: #00FFFF;
}
</style>
