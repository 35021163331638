<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {

  },
  methods: {


  },
}

</script>
<style>
* {
  padding: 0;
  margin: 0;
}

#app {
  width: 100%;
  height: 100%;
}
</style>
