<template>
    <div class="bg">
        <topheader />

        <div class="container">
            <div class="left">
                <zhhyLeft></zhhyLeft>
            </div>
            <div class="middle">
                <zhhymiddle></zhhymiddle>
            </div>
            <div class="right">
                <zhhyRight></zhhyRight>
            </div>
        </div>
    </div>
</template>

<script>
import draw from '@/mixin/index';
import topheader from '@/components/topheader.vue';
import zhhyLeft from '@/components/zhhyLeft.vue';
import zhhymiddle from '@/components/zhhymiddle.vue';
import zhhyRight from '@/components/zhhyRight.vue';
export default {
    name: 'home',
    mixins: [draw],
    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
    components: {
        topheader,
        zhhyLeft,
        zhhyRight,
        zhhymiddle
    }
};
</script>

<style  scoped>
.container {
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.bg {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: url('../../img/bg.png') no-repeat;
    background-size: 100% 100%;
    object-fit: cover;
    /* background-position: 0px -206px; */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
</style>