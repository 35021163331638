<template>
  <div class="Military scroll" ref="scroll" :style="{ height: TableBodyHeight }">
    <!-- <vueSeamlessScroll :data="suqiuTypeData" :class-option="classOption"> -->
    <div v-for="(item, index) in TableData" :key="index" class="Military_item">
      <div class="title">
        <div class="index" v-show="index <= 2" :class="index < 3 ? 'index' + index : 'index3'">
          No.{{ index + 1 }}
        </div>
        <div class="index" v-show="index > 2" :class="index < 3 ? 'index' + index : 'index3'">
          0{{ index + 1 }}
        </div>
        <div class="name" :title="item.name" @click="nameclick(item)">
          {{ item.name }}
        </div>
      </div>

      <div class="item_center">


        <div class="line">
          <div class="bgline" :style="{ width: item.rate }">
            <div class="colorline"></div>
          </div>
        </div>
      </div>
      <div></div>
      <div class="value" :title="item.value">{{ item.value }}</div>

    </div>
    <!-- </vueSeamlessScroll> -->
  </div>
</template>

<script>
// import vueSeamlessScroll from 'vue-seamless-scroll';
export default {

  props: {
    // 表头文字大小
    HeaderFontSize: {
      type: String,
      default: () => {
        return '12px';
      },
    },
    //  表格容器
    TableBodyHeight: {
      type: String,
      default: () => {
        return '290px';
      },
    },
    //  单行表格行高
    TableListHeight: {
      type: String,
      default: () => {
        return '41px';
      },
    },
    // 单行表格高度
    TableListLineHeight: {
      type: String,
      default: () => {
        return '41px';
      },
    },
    // 表格文字大小
    TabliListFontSize: {
      type: String,
      default: () => {
        return '12px';
      },
    },
    // 表格是否超出滚动
    TableIsScroll: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    // 表格超过几个滚动
    TableScrollNum: {
      type: Number,
      default: () => {
        return 5;
      },
    },
    // 滚动间隔时间
    TableIsScrollTime: {
      type: Number,
      default: () => {
        return 3000;
      },
    },
    TableData: {
      type: Array,
      default: () => {
        return [

        ];
      },
    },
  },
  components: {
    // vueSeamlessScroll,
  },
  created() { },
  data() {
    return {
      all: '',
      SetInte: null,
    };
  },
  mounted() {
    let that = this;
    that.tableScroll();
  },
  computed: {
    classOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 6, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    /**
     *函数说明 表格滚动
     *@constructor
     *@author zhangheng
     **/
    tableScroll() {

      let that = this;
      let Scroll = function () {
        // 几个以上开始滚动
        let TableScrollNum = that.TableScrollNum;

        // 每个列表的高度
        let ContThisHeight = parseInt(that.TableListHeight);

        // 列表的总高度
        let TableBodyHeight = parseInt(that.TableBodyHeight);
        // 容器
        let ContScroll = that.$refs.scroll;

        // 首先判断滚动开关开启
        // 并且判断表格个数是否大于超过TableScrollNum滚动的条件
        if (that.TableIsScroll && that.TableData.length > TableScrollNum) {
          // 容器的高度-可视高度 == scrollTop

          if (
            ContScroll.scrollHeight - TableBodyHeight ==
            ContScroll.scrollTop
          ) {

            ContScroll.scrollTo(0, 0);
          } else {

            ContScroll.scrollTo({
              top: ContScroll.scrollTop + ContThisHeight,
              left: 0,
              behavior: 'smooth',
            });

          }
        }
      };
      that.$set(that, 'SetInte', setInterval(Scroll, that.TableIsScrollTime));
    },
    /**
     *鼠标经过关闭滚动
     *@constructor TableMouseOver
     *@author zhangheng
     **/
    TableMouseOver() {
      if (!this.TableIsShow) {
        // console.log('鼠标经过');
        clearInterval(this.SetInte);
        this.$set(this, 'SetInte', null);
      }
    },
    /**
     *鼠标离开滚动
     *@constructor TableMouseOut
     *@author zhangheng
     **/
    TableMouseOut() {
      // 只有当前非高亮，鼠标离开才会开启定时器
      if (!this.TableIsShow) {
        // console.log('鼠标离开');
        this.tableScroll();
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.SetInte);
    this.$set(this, 'SetInte', null);
  },
};
</script>

<style scoped lang="less">
.Military {
  width: 510px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;

  // justify-content: space-between;
  overflow: hidden;
  position: relative;

  .title {
    position: absolute;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .name {
      color: #03A6E9;
    }
  }

  .Military_item {
    width: 380px;
    height: 47px;
    display: flex;
    align-items: center;

    color: #02C1F4;

    .index {
      width: 72px;
      height: 34px;
      line-height: 34px;

      font-size: 16px;

      font-family: 'Square721 Ex BT';

      margin-right: 10px;
    }

    .index0 {
      width: 80px;

    }

    .index1 {
      width: 80px;


    }

    .index2 {
      width: 80px;


    }

    .index3 {
      width: 80px;

    }

    .item_center {
      display: flex;
      box-sizing: border-box;
      margin-bottom: 3px;
      line-height: 10px;
      width: 100%;
      margin-top: 30px;

      .name {
        width: 200px;
        white-space: nowrap;
        font-family: '碳纤维正中黑简体';
        font-size: 16px;
        color: #bfebff;
        text-overflow: ellipsis;
      }

      .line {
        width: 506px;
        height: 8px;
        background: rgba(#3e4f62, 0.7);

        .bgline {
          height: 8px;

          .colorline {
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, #0477D5, #01D9FE);
            animation: lineAddWidth 1s;
			border-radius: 4px;
          }
        }
      }
    }

    .value {
      width: 100px;

      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: right;
      margin: 0px 5px -25px 15px;
      font-family: 'Voltage';
      font-size: 14px;
      color: #02C1F4;
    }


  }

  .Military_item:nth-child(1) {
    color: #F55231;

    .name {
      color: #F55231
    }

    .item_center .line {
      .colorline {
        background: #F55231;
      }
    }

    .value {
      color: #F55231
    }
  }

  .Military_item:nth-child(2) {
    color: #F59331;

    .name {
      color: #F59331;
    }

    .item_center .line {
      .colorline {
        background: #F59331;
      }
    }

    .value {
      color: #F59331;
    }
  }

  .Military_item:nth-child(3) {
    color: #66B766;

    // .index{
    //   background: url("../../../assets/img/ZhiHuiDangJian/top3.png");
    // }
    .item_center .line {
      .colorline {
        background: #66B766;
      }
    }

    .name {
      color: #66B766;
    }

    .value {
      color: #66B766;
    }
  }

}

.scroll::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 7px;
}

.scroll::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background: rgba(160, 160, 160, 0.6);
}

.scroll::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: rgba(255, 255, 255, 0);
}

@keyframes lineAddWidth {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}
</style>
