<template>
  <div>
    <div ref="echart" :style="{ width: width + 'px', height: height + 'px' }"></div>

  </div>
</template>

<script>
export default {
  name: 'LargeScreenListOne',
  props: {
    width: {
      type: String,
      default: "100"
    },
    height: {
      type: String,
      default: "100"
    },
    Xdata : {
      type: Array,
      default: ()=>{
        return []
      }
    },
    Ydata : {
      type: Array,
      default: ()=>{
        return []
      }
    }
  },
  data() {
    return {

    };
  },

  mounted() {
    this.drawChart();
  },

  methods: {
    drawChart() {
      console.log(this.$echarts)
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(this.$refs.echart);
      // 指定图表的配置项和数据
      let option = {

        tooltip: {},
        legend: {
          data: ["销量"],
        },
        xAxis: {
          data: this.Xdata,
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff',  //更改坐标轴文字颜色
              fontSize: 14      //更改坐标轴文字大小
            }
          },
        },
        yAxis: {
          name: "辆",
          nameTextStyle: {
            color: "#fff",
            nameLocation: "start",
          },
          axisLabel: {
           
            textStyle: {
              color: '#fff',  //更改坐标轴文字颜色
              fontSize: 14      //更改坐标轴文字大小
            }
          },
          splitLine:{
      //设置条纹显示 柱状图默认yAxis(true)
      show:true,
      //设置lineStyle即可写入样式即可
      lineStyle:{
      //这里输入线条的样式
        color:'#33cccc',
      }
    }

        },
        series: [
          {
          
            type: "line",
            data: this.Ydata,
            color: ['#33cccc'],
            symbol: 'none',  //取消折点圆圈
            areaStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#33cccc", // 0% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: "#33cccc", // 100% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(0,0,0,0)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },

          },

        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

  },
};
</script>

<style  scoped></style>