import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import echarts from "echarts";

Vue.prototype.$echarts = echarts;
import dataV from "@jiaminghi/data-view";
Vue.use(dataV);
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
Vue.config.productionTip = false;
import geoJson from "./utils/china.json";
echarts.registerMap("china", geoJson);

if (process.env.NODE_ENV === 'development') {
  require('./mock/index.js');
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
