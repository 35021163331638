import axios from "axios";
import vue from "vue";

if (process.env.NODE_ENV === "development") {
  //开发环境 do something --- https://apitest.hnhdygyl.com
  axios.defaults.baseURL = "";
} else {
  //生产环境 do something
  axios.defaults.baseURL = "http://screen.hnhdygyl.com";
}

//响应拦截器
axios.interceptors.response.use(
  function (response) {
    //....

    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//请求拦截器
axios.interceptors.request.use(
  function (config) {
    //...

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/*
 * 封装请求方法
 */
const request = function (query) {
  return (
    axios
      .request(query)
      .then((res) => {
        //...
        if (res.data.code === 401) {
          vue.prototype.$$router.push({ path: "/login" });
          return Promise.reject(res.data);
        } else if (res.data.code === 500) {
          return Promise.reject(res.data);
        } else if (res.data.code === 501) {
          return Promise.reject(res.data);
        } else if (res.data.code === 502) {
          vue.prototype.$$router.push({ path: "/login" });
          return Promise.reject(res.data);
        } else {
          return Promise.resolve(res.data);
        }
      })
      //对错误进行处理
      .catch((e) => {
        return Promise.reject(e.message);
      })
  );
};

//post请求  ----> json格式的post请求
export const post = function (url, params) {
  const query = {
    url: url,
    method: "post",
    withCredentials: true, //跨域允许携带cookie
    timeout: 30000,
    data: params,
    headers: { "Content-Type": "application/json", "request-ajax": true },
  };
  return request(query);
};

//Get请求
export const get = function (url, params = {}) {
  const query = {
    url: url,
    method: "get",
    withCredentials: true,
    timeout: 30000,
    params: params,
    headers: { "request-ajax": true },
  };

  return request(query);
};

//post请求
export const form = function (url, params) {
  const query = {
    url: url,
    method: "post",
    withCredentials: true,
    timeout: 30000,
    data: params,
    headers: {
      "Content-Type": "multipart/form-data",
      "request-ajax": true,
    },
  };
  return request(query);
};

//默认导出的是一个对象
