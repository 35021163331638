<template>
    <div class="login">
        <div class="container">
            <div class="loginbox">
                <div class="box">
                    <div class="title">宏运达</div>
                    <div class="name">
                        <div class="houtai">货主端</div>
                        <div class="denglu">· 登录</div>
                    </div>
                    <div class="loginfrom">
                        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
                            class="demo-ruleForm">

                            <el-input icon="el-icon-users" placeholder="请输入用户名"
                                style="  width: 444px;; color: #fff; background: #FFFFFF4C;" type="text"
                                v-model="ruleForm.pass" autocomplete="off">
                                <i slot="prefix"
                                    style=" margin-left: 8px; display: flex;align-items: center; justify-content: center;">
                                    <img class="header-icon" style="width:16px;height:16px " src="../../assets/svg/用户名.svg"
                                        alt="" />
                                </i></el-input>



                            <el-input placeholder="请输入密码" style="  width: 444px;margin-top: 20px;" show-password
                                type="password" v-model="ruleForm.checkPass" autocomplete="off">
                                <i slot="prefix"
                                    style="margin-left: 8px; display: flex;align-items: center; justify-content: center;">
                                    <img class="header-icon" style="width:16px;height:22px " src="../../assets/svg/密码.svg"
                                        alt="" />
                                </i>
                            </el-input>

                            <div style="position: relative;">
                                <el-input aria-placeholder="请输入验证码" style="width: 444px;margin-top: 20px;" type="text"
                                    placeholder="请输入图片验证码" v-model="ruleForm.j_captcha"></el-input>
                                <img src="../../img/card.png"
                                    style="position: absolute;top: 45%; right: 10px; width: 100px; height: 30px;" alt="">
                            </div>
                        </el-form>

                    </div>
                    <div class="remb">
                        <div class="remember " style="display: flex;">
                            <div @click="showduihao1"
                                style="width: 24px; height: 24px; display:flex ;justify-content: center; align-items: center; background-color: #FFFFFF4C;">
                                <img v-if="isshow1" src="../../assets/svg/勾.svg" alt="">
                            </div>
                            <div style="margin-left: 8px;">
                                记住密码
                            </div>
                        </div>
                        <div class="forget">
                            忘记密码
                        </div>
                    </div>
                    <div class="denglu2" @click="login">
                        <el-button class="buttonClass">登录</el-button>
                    </div>
                    <div class="bottom" style="display: flex; flex-wrap: wrap; justify-content: space-between;">
                        <div @click="showduihao2"
                            style="width: 24px; height: 24px; display:flex ;justify-content: center; align-items: center; background-color: #FFFFFF4C;">
                            <img v-if="isshow2" src="../../assets/svg/勾.svg" alt="">
                        </div>
                        <div style="margin-left: 10px; width: 404px; font-size: 14px; color: #fff;">
                            我已阅读并同意
                            <u>《物流平台软件许可及服务协议》</u>
                            以及 <u>《物流平台交易规则》</u>
                        </div>


                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { fetchSubmitLogin } from '@/services/fetch.js';
export default {
    name: 'ProjectIndex',

    data() {
        var validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入用户信息"));
            } else {
                if (this.ruleForm.checkPass !== "") {
                    this.$refs.ruleForm.validateField("checkPass");
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"));
            } else if (value.length < 8 || value.length > 13) {
                callback(new Error("长度必须在8-12之内!"));
            } else {
                callback();
            }
        };
        return {
            isshow1: true,
            isshow2: true,
            loading: false,
            ruleForm: {
                pass: "",
                checkPass: "",
            },
            rules: {
                pass: [{ validator: validatePass, trigger: "blur" }],
                checkPass: [{ validator: validatePass2, trigger: "blur" }],
            },
        };
    },



    mounted() {

    },

    methods: {
        login() {
            fetchSubmitLogin(this.ruleForm).then(res => {
                window.alert(res.message);
            })
        },
        showduihao1() {
            this.isshow1 = !this.isshow1
        },
        showduihao2() {
            this.isshow2 = !this.isshow2
        },
        showPwd() {
            if (this.passwordType === 'password') {
                this.passwordType = ''
            } else {
                this.passwordType = 'password'
            }
            this.$nextTick(() => {
                this.$refs.password.focus()
            })
        },

    },
};
</script>

<style lang="less" scoped>
/deep/.el-input__prefix {
    display: flex;
    align-items: center;
    justify-content: center;
}

.login {
    width: 100%;
    height: 100vh;
    background: url('../../img/bg3.jpg') no-repeat;
    background-size: 100% 100%;


    .container {
        width: 1024px;
        height: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: right;
        padding-left: 456px;
    }

    .loginbox {
        width: 540px;

        background: #3AAC6E;
        border-radius: 24px;
        box-sizing: content-box;
        transform: scale(.9);

        .box {
            margin: auto;
            padding: 72px 48px;

            .title {
                font-size: 56px;
                color: #fff;
                font-family: PingFang SC;
            }

            .name {
                display: flex;
                align-items: center;
                font-size: 36px;
                color: #fff;

                .denglu {
                    margin-left: 10px;
                    font-size: 28px;

                }
            }

            .loginfrom {
                margin-top: 30px;
            }
        }

        .bottom {
            margin-top: 10px;
            color: #fff;
        }

    }

    .remb {
        margin-top: 24px;
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .denglu2 {
        margin-top: 80px;
    }
}

/deep/.el-input__inner {
    height: 56px;
    border: none;
    background: #FFFFFF4C;
    padding-left: 40px !important;
    color: #fff;
    font-size: 20px;
}

/deep/.el-input__inner::placeholder {
    color: #fff;
}

/deep/ .buttonClass {
    width: 444px;
    height: 56px;

    color: #3AAC6E;
    font-size: 20px;

}

// 全局单选框图标换成勾勾
/deep/ .el-radio__input.is-checked .el-radio__inner::after {
    content: '';
    width: 8px;
    height: 3px;
    border: 2px solid white;
    border-top: transparent;
    border-right: transparent;
    text-align: center;
    display: block;
    position: absolute;
    top: 2px;
    left: 1px;
    vertical-align: middle;
    transform: rotate(-45deg);
    border-radius: 0px;
    background: none;
}
</style>
