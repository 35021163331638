<template>
  <div>
    <div class="content">
      <contentBox classname="modal_left_1" :width="'512'" :height="'231'" :title="'月度增长趋势'" @buttonclick="buttonclick1">


      </contentBox>
      <div class="list">
        <!-- <listone :width="'512'" :height="'231'" /> -->
        <linearea :width="'512'" :height="'231'" :rowData="Datalist3" />
      </div>

    </div>
    <!-- <div class="content2">
      <contentBox classname="modal_left_2" :width="'512'" :height="'386'" :title="'司机Top10'" @buttonclick="buttonclick2"
        :buttonList="['接单数量', '运输总量', '运输总额']">
      </contentBox>
      <div class="list2">
        <top5 :TableData="DataList4"></Top5>
      </div>
    </div> -->
    <div class="content2">
      <contentBox classname="modal_left_2" :width="'512'" :height="'386'" :title="'运单监控'" @buttonclick="buttonclick3"
        :buttonList="['新增运单', '签收运单', '审核运单']">
      </contentBox>
      <div class="list2">
        <order :TableData="DataList5"></order>
      </div>
    </div>
    <div class="content3">
      <contentBox classname="modal_left_1" :width="'512'" :height="'250'" :title="'状态监控'" :is-button="false">
      </contentBox>
      <div class="list">
        <div class="bing">
          <LoopChart :clztDatas="clztData" :names="'车辆状态'"></LoopChart>
        </div>
        <div class="bing2">
          <LoopChart :names="'运单状态'" :clztDatas="ddztData" :color="['#e66464', '#f1c435','#54d1ef',]" :data-detil="ydzt">
          </LoopChart>
        </div>
        <div class="bing">
          <LoopChart :names="'司机状态'" :clztDatas="sjztData"></LoopChart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import api from '../api/index'
import contentBox from './contentBox.vue';
import listone from './echarts/listOne.vue'
import acrossOne from './echarts/acrossOne.vue';
import linearea from './echarts/linearea.vue';
// import Top5 from './echarts/top5.vue';
import order from './echarts/order.vue';
import LoopChart from './echarts/LoopChart.vue';


import { fetchScreenDriverTop, fetchScreenMonthTrend, fetchScreenStatus,fetchOrderCount } from '@/services/fetch.js';


export default {
  name: 'LargeScreenZhhyLeft',
  components: {
    contentBox,
    listone,
    acrossOne,
    linearea,
    // Top5,
    order,
    LoopChart
  },
  data() {
    return {
      valarr: [],
      clztData: [],
      ddztData: [],
      sjztData: [],
      ydzt: [{
        name: "运输中",
        color: '#e66464',
      },
      {
        name: "待审核",
        color: '#f1c435',
      },
      {
        name: "已完成",
        color: '#54d1ef',
      }
      ],
      DataList1: {},
      Datalist3: [],

      datalist5: [],

      carstatus: [],

      DataList2: [],
      DataList4: [],
      DataList5: [],
      rowData1: [],

    };
  },

  mounted() {

    const that = this
    fetchScreenMonthTrend().then((res) => {
      this.DataList1 = res;
      this.buttonclick1('司机')
      // this.buttonclick2('接单数量')
      this.buttonclick3('新增运单')
    })
    fetchScreenStatus().then((res) => {
      this.carstatus = res
      this.getcarstatusCar(this.carstatus)

      this.getorderstatus(this.carstatus)
      this.getdrivestatus(this.carstatus)
    });
  },

  methods: {
    getcarstatusCar(data) {

      let names;
      let datas = data.results.car;
      var arr = []
      let sum = 0
      for (var i = 0; i < datas.length; i++) {
        sum += datas[i].number
        if (datas[i].status == 1) {
          names = '通过'
        } else if (datas[i].status == 0) {
          names = '待审核'
        } else if (datas[i].status == -1) {
          names = '已打回'
        }
        this.valarr.push(names)
      }
      this.valarr = Array.from(new Set(this.valarr))

      for (var i = 0; i < datas.length; i++) {
        var obj = {}
        if (datas[i].status == 1) {
          obj.name = '通过'
          obj.idx = 1
        } else if (datas[i].status == 0) {
          obj.name = '待审核'
          obj.idx = 2
        } else if (datas[i].status == -1) {
          obj.name = '已打回'
          obj.idx = 3
        }
        obj.value = datas[i].number
        obj.sum = sum
        arr.push(obj)
      }
      arr.sort((a,b) => a.idx - b.idx);
      this.clztData = arr

    },
    getorderstatus(data) {
      let datas = data.results.order;
      var arr = []
      let sum = 0
      for (var i = 0; i < datas.length; i++) {
        sum += parseInt(datas[i].number)

      }

      for (var i = 0; i < datas.length; i++) {
        var obj = {}
        if (datas[i].status == 1) {
          obj.name = '运输中'
          obj.idx = 1
        } else if (datas[i].status == 2) {
          obj.name = '待审核'
          obj.idx = 2
        } else if (datas[i].status == 5) {
          obj.name = '已完成'
          obj.idx = 3
        }
        obj.value = parseInt(datas[i].number)
        obj.sum = sum
        arr.push(obj)
      }
      arr.sort((a,b) => a.idx - b.idx);
      this.ddztData = arr

    },
    getdrivestatus(data) {
      let datas = data.results.driver;
      var arr = []
      let sum = 0
      for (var i = 0; i < datas.length; i++) {
        sum += parseInt(datas[i].number)

      }
      for (var i = 0; i < datas.length; i++) {
        var obj = {}
        if (datas[i].status == 1) {
          obj.name = '通过'
          obj.idx = 1;
        } else if (datas[i].status == 0) {
          obj.name = '待审核'
          obj.idx = 2;
        } else if (datas[i].status == -1) {
          obj.name = '已打回'
          obj.idx = 3;
        }
        obj.value = parseInt(datas[i].number)
        obj.sum = sum
        arr.push(obj)
      }
      arr.sort((a,b) => a.idx - b.idx);
      this.sjztData = arr
    },
    disposeRowData1(data) {

      var arr = []
      for (var i = 0; i < data.length; i++) {
        let Obj = {}
        Obj.legend = '类型1'
        Obj.dimension = data[i].censusDate.slice(data[i].censusDate.length - 2, data[i].censusDate.length)
        Obj.measure = data[i].number
        arr.push(Obj)
      }
      this.Datalist3 = arr
    },
    buttonclick1(e) {
      if (e == '司机') {
        this.Datalist3 = this.DataList1.results.driver
        this.disposeRowData1(this.Datalist3)
      } else if (e == '车辆') {
        this.Datalist3 = this.DataList1.results.car
        this.disposeRowData1(this.Datalist3)
      }
    },
    buttonclick2(e) {
      fetchScreenDriverTop().then(res => {

        this.DataList2 = res;
        if (e == '接单数量') {
          let sum = 0;
          let arr = []
          for (var i = 0; i < this.DataList2.results.receipt.length; i++) {

            sum += parseInt(this.DataList2.results.receipt[i].number)
          }
          for (var i = 0; i < this.DataList2.results.receipt.length; i++) {
            let obj = {}
            obj.name = this.DataList2.results.receipt[i].carNo
            obj.value = this.DataList2.results.receipt[i].number
            obj.rate = new Number(this.DataList2.results.receipt[i].number / sum).toFixed(4) * 100 + '%'

            arr.push(obj)

          }
          this.DataList4 = arr
        } else if (e == '运输总量') {

          let sum = 0;
          let arr = []
          for (var i = 0; i < this.DataList2.results.coal.length; i++) {

            sum += this.DataList2.results.coal[i].sumWeight

          }

          for (var i = 0; i < this.DataList2.results.coal.length; i++) {
            let obj = {}
            obj.name = this.DataList2.results.coal[i].carNo
            obj.value = this.DataList2.results.coal[i].sumWeight
            obj.rate = new Number(this.DataList2.results.coal[i].sumWeight / sum).toFixed(4) * 100 + '%'

            arr.push(obj)

          }

          this.DataList4 = arr
        } else if (e == '运输总额') {
          let sum = 0;
          let arr = []
          for (var i = 0; i < this.DataList2.results.freight.length; i++) {

            sum += this.DataList2.results.freight[i].sumPrice

          }

          for (var i = 0; i < this.DataList2.results.freight.length; i++) {

            let obj = {}
            obj.name = this.DataList2.results.freight[i].carNo
            obj.value = this.DataList2.results.freight[i].sumPrice
            obj.rate = new Number(this.DataList2.results.freight[i].sumPrice / sum).toFixed(4) * 100 + '%'

            arr.push(obj)
          }
          this.DataList4 = arr
        }
      })
    },
    buttonclick3(e) {
      fetchOrderCount().then(res => {
        if (e == '新增运单') {
          let sum = 0;
          let arr = []
          for (var i = 0; i < res.results.transportOrder.length; i++) {

            sum += res.results.transportOrder[i].number

          }

          for (var i = 0; i < res.results.transportOrder.length; i++) {

            let obj = {}
            obj.name = res.results.transportOrder[i].niceName
            obj.value = res.results.transportOrder[i].number
            obj.rate = new Number(res.results.transportOrder[i].number / sum).toFixed(4) * 100 + '%'

            arr.push(obj)
          }
          this.DataList5 = arr
        } else if (e == '签收运单') {
          let sum = 0;
          let arr = []
          for (var i = 0; i < res.results.receiptOrder.length; i++) {

            sum += res.results.receiptOrder[i].number

          }

          for (var i = 0; i < res.results.receiptOrder.length; i++) {

            let obj = {}
            obj.name = res.results.receiptOrder[i].niceName
            obj.value = res.results.receiptOrder[i].number
            obj.rate = new Number(res.results.receiptOrder[i].number / sum).toFixed(4) * 100 + '%'

            arr.push(obj)
          }
          this.DataList5 = arr
        } else if (e == '审核运单') {
          let sum = 0;
          let arr = []
          for (var i = 0; i < res.results.checkOrder.length; i++) {

            sum += res.results.checkOrder[i].number

          }

          for (var i = 0; i < res.results.checkOrder.length; i++) {

            let obj = {}
            obj.name = res.results.checkOrder[i].niceName
            obj.value = res.results.checkOrder[i].number
            obj.rate = new Number(res.results.checkOrder[i].number / sum).toFixed(4) * 100 + '%'

            arr.push(obj)
          }
          this.DataList5 = arr
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.content {
  position: relative;
  width: 520px;
  padding: 10px;
}

.list {
  position: absolute;
  top: 9px;
  left: 3px;
}

.content2 {
  margin-top: 30px;
  position: relative;
  width: 520px;
  padding: 10px;

  .list2 {
    position: absolute;
    top: 18px;
    padding-left: 38px;
  }
}

.content3 {
  margin-top: 25px;
  position: relative;
  width: 520px;
  padding: 10px;

  .list {
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 60px;
    width: 100%;

    position: absolute;
    top: 40px;
    left: 20px;
    display: flex;
    justify-content: space-between;

    .bing2 {

      .contents {
        /deep/.zt {
          margin-left: -30px;
        }

        /deep/.bottom {
          margin-left: -30px;

          .box:nth-child(3) {
            margin-left: 5px;
          }
        }
      }


    }
  }
}
</style>
