<template>
  <div class="contents">
    <div class="bg">
      <img src="../../img/spin.png" alt="">
    </div>
    <div class="echarts" ref="echart" :style="{ width: width + 'px', height: height + 'px' }"></div>
    <div class="zt">
      {{ names }}
    </div>
    <div class="bottom">
      <div class="box" v-for="(item, i) in dataDetil">
        <div class="title" :style="{ background: item.color, }"></div>
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>


export default {

  props: {
    names: {
      type: String,
      default: "车辆状态"
    },
    width: {
      type: String,
      default: "140"
    },
    height: {
      type: String,
      default: "140"
    },

    dataDetil: {
      type: Array,
      default: () => {
        return [
          {
            name: "通过",
            color: '#e66464',
          },
          {
            name: "待审核",
            color: '#f1c435',
          }

        ]
      }
    },
    clztDatas: {
      type: Array,
      default: () => {
        return [
          {
            name: "通过",
            value: '0',
            sum: 10
          },
          {
            name: "待审核",
            value: '0',
            sum: 10
          }

        ]
      }
    },
    color: {
      type: Array,
      default: () => {
        return ['#e66464', '#f1c435',]
      }
    }


  },
  data() {
    return {
      series: [],
      clztDatas2: []
    };
  },

  mounted() {
    this.placeCharts();

  },
  watch: {
    // clztDatas(now, old) {
    //   console.log('old', old, 'now', now)
    // }
    clztDatas: {
      handler(newVal, oldVal) {
        //todo

        this.clztDatas = newVal
        this.placeCharts()
      },
      // immediate: true,
      deep: true // 可以深度检测到 person 对象的属性值的变化
    }
  },
  methods: {

    placeCharts() {
      let arr = []
      for (var i = 0; i < this.clztDatas.length; i++) {

        let obj = {
          type: 'bar',
          data: [0, 0, 0, this.clztDatas[i].value],
          coordinateSystem: 'polar',
          name: this.clztDatas[i].name,
          roundCap: false,
          stack: 'a',
          label: {
             color: "#000",
             show: true,
             position: "top",
             width: 10,
             height: 0,
             lineHeight: 0,
             labelLine: {
               length: 2,
               length2: 10,
             },
             formatter: function (p) {
               return "11323421";
             },

             align: "center",
          }

        }
        arr.push(obj)
      }
      this.series = arr
      // this.series = [{
      //   type: 'bar',
      //   data: [0, 0, 0, 2],
      //   coordinateSystem: 'polar',
      //   name: 'A',
      //   roundCap: true,
      //   stack: 'a',

      // },
      // {
      //   type: 'bar',
      //   data: [0, 0, 0, 2],
      //   coordinateSystem: 'polar',
      //   name: 'b',
      //   roundCap: true,
      //   stack: 'a',

      // },
      // {
      //   type: 'bar',
      //   data: [0, 0, 0, 2],
      //   coordinateSystem: 'polar',
      //   name: 'c',
      //   roundCap: true,
      //   stack: 'a',

      // }]
      let myChart = this.$echarts.init(this.$refs.echart);

      // let tooltipData;
      // for (var i = 0; i < this.clztDatas.length; i++) {
      //   tooltipData += this.clztDatas[i].name + parseInt(this.clztDatas[i].value) * parseInt(this.clztDatas[i].sum)
      // }
      // console.log('bbbbbbbbbbbb', this.clztDatas[1].sum)
      let option = {

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            animation: false,
            label: {
              backgroundColor: '#505765',
            },
          },
        },
        angleAxis: {
          clockwise: false, // 刻度增长是否按顺时针，默认顺时针(true)。
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          min: 0,
          // max: this.clztDatas.sum, //一圈的刻度值
          startAngle: 0 //初始角度
        },
        radiusAxis: {
          type: 'category',
          data: ['1', '2', '3', '4'], // 极坐标径向轴中的类目，这边有几个数，
          // 就代表径向轴分了几份，和series中的data对应，这样就可以撑开圆环
          z: 10,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
        },
        polar: {
          tooltip: {
            show: true,
            axisPointer: {
              type: 'none',
              label: {
                show: false
              }
            }
          }
        },
        color: this.color,
        series: this.series

      };


      myChart.setOption(option);
    }
  }
};
</script>

<style lang="less" scoped>
.contents {
  width: 128px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .echarts {
    position: absolute;
    top: -5px;
    left: -5px;
  }
}

.bg {
  width: 128px;
  height: 128px;

  img {
    width: 100%;
    height: 100%;
  }
}

.bottom {
  margin-top: 10px;
  display: flex;

  .box {
    display: flex;
    align-items: center;

    .title {
      margin-top: 5px;
      width: 12px;
      height: 12px;

    }

    .name {
      margin-left: 10px;
      width: 36px;
      height: 12px;
      font-size: 12px;
      color: #00F6FF;
    }
  }
}

.zt {
  text-align: center;
  color: #00F6FF;
  color: 16px;
}
</style>
