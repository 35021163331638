<template>
  <div class="tableContent">
    <div class="table-content" :style="{ width: TbaleContentWidth }">
      <div class="content-body scroll" ref="scroll" :style="{ height: TableBodyHeight }">
        <div class="contScroll">
          <!-- :key 到时候需要修改放唯一ID  -->
          <div class="list" v-for="(val, ind) in TableData" :key="ind" :style="[
            tableBodySetup.TableIsBlank
              ? (ind + 1) % 2 == 0
                ? tableBodySetup.TableListEven
                : tableBodySetup.TableListOdd
              : tableBodySetup.TabListBg,
            val.__table_show_ ? tableBodySetup.HighlightStyle : '',
            {
              color: tableBodySetup.TableListColor,
              fontSize: TabliListFontSize,
              height: TableListHeight,
              lineHeight: TableListLineHeight,
            },
          ]">
            <ul>
              <li v-for="(item, ObjKey, index) in val" :key="index" :style="{ width: computeWidth(ObjKey) }">

                <div>
                  <span v-show="index === 0 && ind === 0">
                    <img src="../../img/TOP1.png" alt="">


                  </span>
                  <span v-show="index === 0 && ind === 1">
                    <img src="../../img/TOP2.png" alt="">


                  </span>
                  <span v-show="index === 0 && ind === 2">
                    <img src="../../img/TOP3.png" alt="">


                  </span>
                  <span class="titleindex" v-show="index == 1 && isShowIndex"> {{ `TOP${ind + 1}` }}</span>
                  <span v-show="index == 3 && isShowIndex">
                    <img src="../../img/jiantou.png" alt="">
                  </span>
                  <span :title="item" v-show="index != 1 && index != 3">{{
                    item
                  }}</span>{{ val.__show }}
                </div>

              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonTable2',
  props: {
    //是否显示索引
    isShowIndex: {
      type: Boolean,
      default: true,
    },
    // 索引背景图
    indexBak: {
      type: String,
      default: () => {
        return '';
      },
    },
    // 是否开启颜色修改
    itemColorStart: {
      type: Boolean,
      default: false,
    },
    // 整体宽度
    TbaleContentWidth: {
      type: String,
      default: () => {
        return '542px';
      },
    },
    // 表格头部高度
    HeaderHeight: {
      type: String,
      default: () => {
        return '50px';
      },
    },
    // 表头文字大小
    HeaderFontSize: {
      type: String,
      default: () => {
        return '12px';
      },
    },
    //  表格容器
    TableBodyHeight: {
      type: String,
      default: () => {
        return '210px';
      },
    },
    //  单行表格行高
    TableListHeight: {
      type: String,
      default: () => {
        return '30px';
      },
    },
    // 单行表格高度
    TableListLineHeight: {
      type: String,
      default: () => {
        return '30px';
      },
    },
    // 表格文字大小
    TabliListFontSize: {
      type: String,
      default: () => {
        return '12px';
      },
    },
    // 表格是否超出滚动
    TableIsScroll: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    // 表格超过几个滚动
    TableScrollNum: {
      type: Number,
      default: () => {
        return 5;
      },
    },
    // 滚动间隔时间
    TableIsScrollTime: {
      type: Number,
      default: () => {
        return 3000;
      },
    },
    // 表格头数据
    /**
     *参数说明 固定格式
     *@constructor tableHeader
     *@author zhangheng
     *@param  {String} align 文字对齐方向默认向左
     *@param  {String} name 表头
     *@param  {String} width 宽度
     *@param  {String} key 表格内容的参数
     **/
    tableHeader: {
      type: Array,
      default: () => {
        return [ ];
      },
    },
    // 数据
    /**
     *参数说明 参数以tableHeader   key  个数为准 最后data 为额外参数 如 id code ==
     *@constructor TableData
     *@author zhangheng
     *@param  {data} 额外参数
     **/
    TableData: {
      type: Array,
      default: () => {
        return [
          {
            level: '',
            type: '',
            start: '浙江杭州上城区',
            street: '',
            end: '浙江宁波鄞州区',
          },
          {
            level: '',
            type: '',
            start: '浙江杭州上城区',
            street: '',
            end: '浙江宁波鄞州区',
          },
          {
            level: '',
            type: '',
            start: '浙江杭州上城区',
            street: '',
            end: '浙江宁波鄞州区',
          },
          {
            level: '',
            type: '',
            start: '浙江杭州上城区',
            street: '',
            end: '浙江宁波鄞州区',
          },
          {
            level: '',
            type: '',
            start: '浙江杭州上城区',
            street: '',
            end: '浙江宁波鄞州区',
          },
          {
            level: '',
            type: '',
            start: '浙江杭州上城区',
            street: '',
            end: '浙江宁波鄞州区',
          },
          {
            level: '',
            type: '',
            start: '浙江杭州上城区',
            street: '',
            end: '浙江宁波鄞州区',
          },
          {
            level: '',
            type: '',
            start: '浙江杭州上城区',
            street: '',
            end: '浙江宁波鄞州区',
          },
          {
            level: '',
            type: '',
            start: '浙江杭州上城区',
            street: '',
            end: '浙江宁波鄞州区',
          },
          {
            level: '',
            type: '',
            start: '浙江杭州上城区',
            street: '',
            end: '浙江宁波鄞州区',
          },
        ];
      },
    },



  },
  data() {
    return {
      // 表头内容
      tableHeadersSetUp: {
        // 表头背景颜色
        HeaderBgColor: '',
        // 表头文字颜色
        // HeaderFontColor: "#fff",
        HeaderFontColor: '#D5F7FD',
        // 表头边线颜色
        HeaderBorder: '1px solid #012437',
      },
      // 表格设置
      tableBodySetup: {
        // 是否隔行变色
        TableIsBlank: true,
        // 偶数样式
        TableListEven: {
          background: '',
        },
        // 奇数样式
        TableListOdd: {
          background:'#224F6A',
        },
        // 如果不开启隔行变色样式
        TabListBg: {
          background: '',
        },
        // 点击高亮样式
        HighlightStyle: {
          background: 'rgba(217,163,0,0.35)',
        },
        // 文字颜色
        TableListColor: '#D5F7FD',
      },
      // 滚动方法
      SetInte: null,
      // 判断鼠标离开是否高亮
      TableIsShow: false,
    };
  },
  computed: {

    tableHeaders: {
      get() {
        return this.tableHeader;
      },
      set() { },
    },
  },
  methods: {
    /**
     *函数说明
     *@constructor computeWidth
     *@author zhangheng
     *@param  {String}  Strkey - 参数名 参数说明
     *@return {String} 返回width
     **/
    computeWidth(Strkey) {
      let Str = '';
      for (let i = 0; i < this.tableHeaders.length; i++) {
        if (Strkey == this.tableHeaders[i].key) {
          Str = this.tableHeaders[i].width;
        }
      }
      return Str;
    },
    /**
     *函数说明 表格滚动
     *@constructor
     *@author zhangheng
     **/
    tableScroll() {
      let that = this;
      let Scroll = function () {
        // 几个以上开始滚动
        let TableScrollNum = that.TableScrollNum;
        // 每个列表的高度
        let ContThisHeight = parseInt(that.TableListHeight);
        // 列表的总高度
        let TableBodyHeight = parseInt(that.TableBodyHeight);
        // 容器
        let ContScroll = that.$refs.scroll;

        // 首先判断滚动开关开启
        // 并且判断表格个数是否大于超过TableScrollNum滚动的条件
        if (that.TableIsScroll && that.TableData.length > TableScrollNum) {
          // 容器的高度-可视高度 == scrollTop

          if (
            ContScroll.scrollHeight - TableBodyHeight ==
            ContScroll.scrollTop
          ) {
            ContScroll.scrollTo(0, 0);
          } else {
            ContScroll.scrollTo({
              top: ContScroll.scrollTop + ContThisHeight,
              left: 0,
              behavior: 'smooth',
            });
          }
        }
      };
      that.$set(that, 'SetInte', setInterval(Scroll, that.TableIsScrollstart));
    },









  },
  mounted() {
    // 注释：获取到数据以后需要在.ftl 里面 class='list' :key 更换成唯一ID
    let that = this;
    // 开启定时器
    that.tableScroll();
  },
  // 在页面销毁之前清除掉定时器
  beforeDestroy() {
    clearInterval(this.SetInte);
    this.$set(this, 'SetInte', null);
  },
};
</script>

<style scoped>
.tableContent {
  margin-top: 10px;
}

.table-content .table-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  width: 100%;
  /* text-align: left;
  padding: 0;
  margin: 0; */
}

.table-content .table-header li {
  /* padding: 0 10px 0 12px; */
  -webkit-box-sizing: border-box;
  /* box-sizing: border-box; */
  padding: 0 0 0 0;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table-content .table-header li:last-child {
  border-right: none !important;
}

.table-content .content-body {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.table-content .content-body .contScroll {
  position: absolute;
  width: 100%;
  height: 100%;
}

.table-content .content-body .list {
  width: 100%;
}

.table-content .content-body .list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-align: center;
  justify-content: space-between;
}

.table-content .content-body .list ul li {
  flex:1;
  padding: 0 10px 0 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table-content .content-body .list ul li .titleindex {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;

  font-size: 12px;
  color: rgb(213, 247, 253);
}

.table-content .scroll::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 7px;
}

.table-content .scroll::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background: rgba(160, 160, 160, 0.6);
}

.table-content .scroll::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: rgba(255, 255, 255, 0);
}

.onLineColor {
  color: #4cffa5;
}

.conductColor {
  color: #eaf213;
}
</style>
