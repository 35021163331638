import { get,post } from "@/api/index.js";
export const fetchScreenDriverTop = params => get('/screen_api/screen/driverTop', params);
export const fetchScreenMonthTrend = params => get('/screen_api/screen/monthTrend', params);
export const fetchScreenStatus = params => get('/screen_api/screen/status', params);
export const fetchOrderMap = params => get('/screen_api/order/map', params);
export const fetchScreenOrderList = params => get('/screen_api/screen/orderList', params);
export const fetchScreenRouteList = params => get('/screen_api/screen/routeList', params);
export const fetchScreenOwnerTop = params => get('/screen_api/screen/ownerTop', params);
export const fetchScreenAnalysis = params => get('/screen_api/screen/analysis', params);
export const fetchScreenTotal = params => get('/screen_api/screen/total', params);
export const fetchSubmitLogin = params => post('/screen_api/login',params);
export const fetchOrderCount = params => post('/screen_api/screen/orderCount',params);