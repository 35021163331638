<template>
    <div>
        <div class="content1">
            <contentBox classname="china_map" :width="'818'" :height="'650'" :is-button="false" :istitle="false" />
            <div class="list">
                <chinaMap></chinaMap>
            </div>
        </div>
        <div class="middleBottom">
            <div class="content2">
                <contentBox classname="modal_left_1" :width="'400'" :height="'250'" :title="'运单监控'" :is-button="false">
                </contentBox>
                <div class="list">
                    <CommonTable1 :TbaleContentWidth="'350px'" :TableData="TableData"></CommonTable1>
                </div>
            </div>
            <div class="content2">
                <contentBox  classname="modal_left_1" :width="'400'" :height="'250'" :title="'热门路线运次排名（月度）'" :is-button="false">
                </contentBox>
                <div class="list">
                    <CommonTable2 :TbaleContentWidth="'350px'" :TableData="TableData2"></CommonTable2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import chinaMap from "./chinaMap.vue";
import contentBox from "./contentBox.vue";
import LoopChart from "./echarts/LoopChart.vue";
import CommonTable1 from "./echarts/CommonTable1.vue";
import CommonTable2 from "./echarts/CommonTable2.vue";
import { fetchScreenRouteList,fetchScreenOrderList} from '@/services/fetch.js'
export default {
    name: "zhhymiddle",
    components: {
        contentBox,
        CommonTable1,
        LoopChart,
        CommonTable2,
        chinaMap,
    },
    data() {
        return {

            TableData: [


            ],
            TableData2: [


            ]
        };
    },

    mounted() {
        fetchScreenOrderList().then(res => {
            this.TableData = res
            this.gettableData(this.TableData)
        });
        fetchScreenRouteList().then(res => {
            this.TableData2 = res
             this.gettableData2(this.TableData2)
        })
    },

    methods: {
        gettableData(data) {

            var arr = []
            let datas = data.results.order
            for (var i = 0; i < datas.length; i++) {

                let obj = {}
                obj.name = datas[i].carNo
                obj.from = datas[i].startAddress
                obj.end = datas[i].endAddress
                obj.goodname = datas[i].goodType
                if (datas[i].status == 1) {
                    obj.state = '运输中'
                } else {
                    obj.state = ''
                }

                arr.push(obj)
            }
            this.TableData = arr
        },
        gettableData2(data) {
            var arr = []
            let datas = data.results.route
            for (var i = 0; i < datas.length; i++) {

                let obj = {}
                obj.level = ''
                obj.type = ''
                obj.start = datas[i].startAddress

                obj.street = ''
                obj.end = datas[i].endAddress
                arr.push(obj)

            }
            this.TableData2 = arr
        }
    },
};
</script>

<style lang="less" scoped>
.content1 {
    margin-top: 25px;
    position: relative;

    .list {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.middleBottom {
    width: 100%;

    margin-top: 35px;
    display: flex;
    justify-content: space-between;
}

.content2 {
    margin-left: -10px;
    position: relative;
    width: 100%;
    padding: 10px;

    .list {
        position: absolute;
        top: 30px;
        left: 40px;
    }
}
</style>
