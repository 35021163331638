<template>
  <div>
    <div class="content">
      <contentBox  classname="modal_left_1" :width="'512'" :height="'231'" :title="'货主Top10'" @buttonclick="buttonclick1"
        :buttonList="['发布总量', '完成总量', '运输总量']">
      </contentBox>
      <div class="list">
        <BarChart :isrotate="true" :chartStylr="{ unit: '' }" :styleBarChart="{
          width: '512px',
          height: '200px',
        }" :coloreBarChart="['rgba(216, 26, 245, 1)', 'rgba(216, 26, 245, 0)']" :rowData="Data"></BarChart>
      </div>

    </div>
    <div class="content2">
      <contentBox  classname="modal_left_2"  :width="'512'" :height="'386'" :title="'统计分析'" :is-button="false">
      </contentBox>
      <div class="list2">
        <div class="tjfx">
          <div class="box">
            <div class="value">
              {{ tjfxData[0].value }} {{ tjfxData[0].unit }}
            </div>
            <div class="title">
              {{ tjfxData[0].name }}
            </div>
            <div class="icon">

            </div>
          </div>
          <div class="box">
            <div class="value"
              style=" background: linear-gradient(90deg, #FFE456 0%, #DEA60F 100%);-webkit-background-clip: text;color: transparent;">
              {{ tjfxData[1].value }} {{ tjfxData[1].unit }}
            </div>
            <div class="title">
              {{ tjfxData[1].name }}
            </div>
            <div class="icon">

            </div>
          </div>
          <div class="box">
            <div class="value"
              style=" background: linear-gradient(90deg,#FC9C45 0%, #FD5066 100%);-webkit-background-clip: text;color: transparent;">
              {{ tjfxData[2].value }} {{ tjfxData[2].unit }}
            </div>
            <div class="title">
              {{ tjfxData[2].name }}
            </div>
            <div class="icon">

            </div>
          </div>
          <div class="box">
            <div class="value"
              style=" background: linear-gradient(90deg,#08E4FA 0%, #0B8FB2 100%);-webkit-background-clip: text;color: transparent;">
              {{ tjfxData[3].value }} {{ tjfxData[3].unit }}
            </div>
            <div class="title">
              {{ tjfxData[3].name }}
            </div>
            <div class="icon">

            </div>
          </div>
          <div class="box">
            <div class="value"
              style=" background: linear-gradient(90deg,#0BE18B 0%, #0AA992  100%);-webkit-background-clip: text;color: transparent;">
              {{ tjfxData[4].value }} {{ tjfxData[4].unit }}
            </div>
            <div class="title">
              {{ tjfxData[4].name }}
            </div>
            <div class="icon">

            </div>
          </div>
          <div class="box">
            <div class="value"
              style=" background: linear-gradient(90deg,#08E5FB 0%, #0B8FB2 100%);-webkit-background-clip: text;color: transparent;">
              {{ tjfxData[5].value }} {{ tjfxData[5].unit }}
            </div>
            <div class="title">
              {{ tjfxData[5].name }}
            </div>
            <div class="icon">

            </div>
          </div>
          <div class="box">
            <div class="value"
              style=" background: linear-gradient(90deg,#FFE457 0%, #DEA60F  100%);-webkit-background-clip: text;color: transparent;">
              {{ parseInt(tjfxData[6].value) }}{{ tjfxData[6].unit }}
            </div>
            <div class="title">
              {{ tjfxData[6].name }}
            </div>
            <div class="icon">

            </div>
          </div>
          <div class="box">
            <div class="value"
              style=" background: linear-gradient(90deg,#3D7FFF 0%, #3263B2  100%);-webkit-background-clip: text;color: transparent;">
              {{ parseFloat(tjfxData[7].value).toFixed(2) }} {{ tjfxData[7].unit }}
            </div>
            <div class="title">
              {{ tjfxData[7].name }}
            </div>
            <div class="icon">

            </div>
          </div>
        </div>

      </div>

    </div>
    <div class="content3">
      <contentBox  classname="modal_left_1"  :width="'512'" :height="'250'" :title="'平台交易总额统计'" :is-button="false">


      </contentBox>
      <div class="list">
        <BarChart :chartStylr="{ unit: '元', barWidth: 5, }" :styleBarChart="{
          width: '512px',
          height: '230px',
        }" :coloreBarChart="['#0477D5', '#01D9FE']" :rowData="ptjyData2"></BarChart>
      </div>

    </div>
  </div>
</template>

<script>
import { get } from '../api/index.js'
import contentBox from './contentBox.vue';
import listone from './echarts/listOne.vue'
import acrossOne from './echarts/acrossOne.vue';
import BarChart from './echarts/BarChart.vue';
import {fetchScreenOwnerTop,fetchScreenTotal,fetchScreenAnalysis} from '@/services/fetch.js';
export default {
  name: 'LargeScreenZhhyLeft',
  components: {
    contentBox, listone, acrossOne, BarChart
  },
  data() {
    return {

      tjfxData: [
        {
          name: "累计货主",
          value: "0",
          unit: "家"
        },
        {
          name: "累计司机",
          value: "0",
          unit: "位"
        },
        {
          name: "累计路线",
          value: "0",
          unit: "条"
        },
        {
          name: "累计车辆",
          value: "0",
          unit: "辆"
        },
        {
          name: "累计运单量",
          value: "0",
          unit: "单"
        },
        {
          name: "月均运单量",
          value: "0",
          unit: "单"
        },
        {
          name: "累计发运量",
          value: "0",
          unit: "万吨"
        },
        {
          name: "已支付运费",
          value: "0",
          unit: "亿"
        },
      ],
      temporaryData: [],
      Data: [],
      Data1: [],
      ptjyData: [],
      ptjyData2: [],
      tjfxData2: {}
    };
  },

  mounted() {
    this.buttonclick1('发布总量')
    fetchScreenAnalysis().then(res => {
      this.tjfxData2 = res
	   this.gettjfxData(this.tjfxData2)
    })
    fetchScreenTotal().then(res => {
      this.ptjyData = res
	  this.getptjyData(this.ptjyData)
    })
  },

  methods: {
    gettjfxData(data) {
      //累计运单

      let car = data.results.car.number
      this.tjfxData[3].value = car
      let comp = data.results.comp.number

      this.tjfxData[0].value = comp
      
	  let weight = data.results.yearCar.number 
      this.tjfxData[4].value = weight
      let price = data.results.price.sumPrice
      this.tjfxData[7].value = price

      let route = data.results.route.number
      this.tjfxData[2].value = route

      let truck = data.results.truck.number
      this.tjfxData[1].value = truck
     
	  let monthAvgOrder = data.results.monthAvgOrder.monthAvgNumOrder
      this.tjfxData[5].value = monthAvgOrder
      let yearCar = data.results.weight.sumWeight
      this.tjfxData[6].value = yearCar


    },
    buttonclick1(e) {

      fetchScreenOwnerTop().then((res) => {
        this.Data1 = res

        if (e == '发布总量') {
          this.Data = this.gettop10Data(this.Data1.results.release)
        } else if (e == '完成总量') {
          this.Data = this.gettop10Data(this.Data1.results.complete)
        } else if (e == '运输总量') {
          this.Data = this.gettop10Data(this.Data1.results.freight)
        }

      })

    },
    gettop10Data(data) {
      var arr = []
      for (var i = 0; i < data.length; i++) {
        var obj = {}
        obj.name = data[i].niceName;

        obj.val = parseInt(data[i].sumWeight)
        arr.push(obj)
      }
      return arr
    },
    getptjyData(data) {
      let datas = data.results.result
      let arr = []
      for (var i = 0; i < datas.length; i++) {
        let obj = {}
        obj.name = datas[i].censusDate
        obj.val = datas[i].sumPrice
        arr.push(obj)
      }
      this.ptjyData2 = arr
    }
  },
};
</script>

<style scoped lang="less">
.content {
  position: relative;
  width: 520px;
  padding: 10px;
}

.list {
  position: absolute;
  top: 40px;
  left: 20px;
}

.content2 {
  margin-top: 30px;
  position: relative;
  width: 520px;
  padding: 10px;
}

.list2 {
  position: absolute;
  top: 50px;
  left: 60px;
  transform: scale(1.1);

  .tjfx {

    width: 400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .box {
      margin-top: 30px;
      width: 100px;
      height: 110px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .value {
        font-size: 22px;
        width: 100%;
        height: 27px;
        background: linear-gradient(90deg, #0BE08B 0%, #0AA992 100%);
        -webkit-background-clip: text;
        color: transparent;
        text-align: center;
        margin-bottom: 6px;
      }

      .title {
        text-align: center;
        width: 100%;
        color: #fff;
      }

      .icon {
        margin-top: 10px;
        width: 53px;
        height: 50px;
        background: url(../img/my.png)100% 100% no-repeat;
            background-size: contain;
      }
    }

  }

  .tjfx .box:nth-child(2) .icon {
    background: url('../img/sj.png') no-repeat;
  }

  .tjfx .box:nth-child(3) .icon {
    width: 66px;
    height: 46px;
    background: url('../img/cl.png') no-repeat;
  }

  .tjfx .box:nth-child(4) .icon {
    width: 51px;
    height: 46px;
    background: url('../img/fx.png') no-repeat;
  }

  .tjfx .box:nth-child(5) .icon {
    width: 45px;
    height: 47px;
    background: url('../img/bj.png') no-repeat;
  }

  .tjfx .box:nth-child(6) .icon {
    width: 51px;
    height: 39px;
    background: url('../img/pj.png') no-repeat;
  }

  .tjfx .box:nth-child(7) .icon {
    width: 83px;
    height: 42px;
    background: url('../img/kd.png') no-repeat;
        background-size: contain;
  }

  .tjfx .box:nth-child(8) .icon {
    width: 52px;
    height: 52px;
    background: url('../img/fy.png') no-repeat;
        background-size: contain;
  }
}

.content3 {
  margin-top: 25px;
  position: relative;
  width: 520px;
  padding: 10px;
}

.list {
  position: absolute;
  top: 36px;
  left: 28px;
}

.content3 .list {
  top: 53px;
  left: 9px;
}
</style>
