<!--
 * @Description: 折线 面积图
-->
<template>
  <div class="lineArea">
    <div ref="lineArea" :style="{ width: `${width}px`, height: `${height}px` }" @mouseover="chartsMouseOver"
      @mouseout="chartsMouseOut" :id="id" :class="Lineclass"></div>
    <div class="legend" :style="legendStyle" v-if="legends.length + legendsRight.length > 1 && hasData">
      <div v-show="islegend === true" class="legendItem" v-for="(legend, index) in legends" :key="index">
        <div class="block" :style="{ 'background-color': legend.color }"
          :class="legend.foreword === 'vertical' ? 'vertical' : ''"></div>
        <div class="text" v-text="legend.name"></div>
      </div>
      <div class="legend1" v-if="legends.length + legendsRight.length > 1 && hasDataRight">
        <div class="legendItem" v-for="(val, index) in legendsRight" :key="index">
          <div class="block" :style="{ 'background-color': val.color }"
            :class="legend.foreword === 'vertical' ? 'vertical' : ''"></div>
          <div class="text" v-text="val.name"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lineArea: null,
      option: null,
      xData: [],
      yData: [],
      yDataRight: [],
      hasData: true,
      hasDataRight: false,
    };
  },
  props: {
    leftwidth: { type: String, default: '9%' }, //滚动条距离左边距离
    endWeith: { type: Number, default: 100 }, //滚动条宽度
    height: { type: String, default: '100' }, //高度
    width: { type: String, default: '100' }, //宽度
    unit: { type: String, default: '' }, //单位
    rightunit: { type: String, default: '' }, //右侧单位
    xAreaFontSize: { type: Number, default: 16 }, //右侧单位
    xAreaFontColor: { type: String, default: '#BFEBFF' }, //x轴字体颜色
    displayModel: {
      type: Number,
      default: 1, //0 折线 1 面积
    },
    zoomShow: { type: Boolean, default: false }, //是否显示滚动条
    islegend: { type: Boolean, default: true },
    smooth: { type: Boolean, default: true }, //是否平滑
    showToolTip: { type: Boolean, default: false }, //是否显示动画
    legendStyle: {
      // 自定义图例
      type: Object,
      default: () => {
        return {};
      },
    },
    padding: {
      type: Array,
      default: () => {
        return [0, 0, 0, 7];
      },
    },
    rowData: {
      type: Array,
      default: () => {
        return [
        ];
      },
    },
    rowDataRight: {
      type: Array,
      default: () => {
        return [];
      },
    },
    //传入一个id
    id: {
      type: String,
      default: 'line_bar_echarts',
    },
    // 传入一个class
    Lineclass: {
      type: String,
      default: 'swipercharts',
    },
    legends: {
      type: Array,
      default: () => {
        return [{ name: '类型1', color: '#00BFFF' }];
      },
    }, //图例数据
    legendsRight: {
      type: Array,
      default: () => {
        return [];
      },
    },
    showRightYaxisLabel: {
      type: Boolean,
      default: false,
    },
    grid: {
      type: Object,
      default: () => {
        return {
          left: '5%',
          right: '2.9%',
          bottom: '10%',
          top: '10%',
          containLabel: true,
        };
      },
    },
    stack: {
      type: Boolean,
      default: false,
    }, //是否堆积
    //最大最小值
    min: { type: String, default: null }, //高度
    max: { type: String, default: null }, //高度
    mins: { type: String, default: null }, //高度
    maxs: { type: String, default: null }, //高度
    symbolSizeIndex: { type: String, default: null },
  },
  mounted() {
    let that = this;
    that.$nextTick(() => {
      if (that.rowData) {
        if (that.rowDataRight) {
          that.transformData1(that.rowDataRight);
          that.hasDataRight = true;
        }
        that.transformData(that.rowData);
        that.drawChart();
        that.tooltipShuffling();
      }
    });
  },
  methods: {
    chartsMouseOver() {
      clearInterval(this.setTime);
    },
    chartsMouseOut() {
      this.tooltipShuffling();
    },
    drawChart() {
      // 基于准备好的dom，初始化echarts实例
      this.lineArea = this.$echarts.init(this.$refs.lineArea);
      this.option = this.initOption();
      this.lineArea.setOption(this.option, true);
    },

    initOption() {
      let that = this;
      if (!that.yData || that.yData.length == 0) {
        that.option = {
          title: {
            text: `暂无数据`,
            show: true,
            textStyle: {
              align: 'center',
              color: '#fff',
              fontSize: 32,
            },
            top: 'center',
            left: 'center',
          },
        };
        that.xData = [];
        that.hasData = false;
        return that.option;
      } else {
        that.hasData = true;
        that.option = {
          tooltip: {
            show: that.showToolTip,
            trigger: 'axis',
            textStyle: {
              fontSize: 18,
            },
            axisPointer: {
              type: 'line',
              animation: false,
              label: {
                backgroundColor: '#505765',
                fontSize: 12,
              },
            },
          },

          grid: this.grid,
          xAxis: [
            {
              type: 'category',
              boundaryGap: true,
              data: that.xData,
              axisLine: {
                lineStyle: {
                  color: '#BFEDFF',
                  fontSize: 12,
                },
              },
              axisLabel: {
                fontSize: this.xAreaFontSize,
                color: this.xAreaFontColor,
                formatter: function (params) {
                  var newParamsName = '';
                  var paramsNameNumber = params.length;
                  var provideNumber = 7; // 一行显示几个字 然后就超过字数就会自动换行
                  var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                  if (paramsNameNumber > provideNumber) {
                    for (var p = 0; p < rowNumber; p++) {
                      var tempStr = '';
                      var start = p * provideNumber;
                      var end = start + provideNumber;
                      if (p == rowNumber - 1) {
                        tempStr = params.substring(start, paramsNameNumber);
                      } else {
                        tempStr = params.substring(start, end) + '\n';
                      }
                      newParamsName += tempStr;
                    }
                  } else {
                    newParamsName = params;
                  }
                  return newParamsName;
                },
              },
              axisTick: {
                show: false,
              },
            },
          ],
          title: [
            {
              text: `${that.unit}`,
              padding: `${this.padding}`,
              textStyle: {
                color: '#DFF2FF',
                fontSize: 12,
              },
              top: 'top',
              left: 'left',
            },
            {
              text: `${this.rightunit}`,
              padding: [5, 5, 0, 0],
              textStyle: {
                color: '#BFEDFF',
                fontSize: 18,
              },
              top: 'top',
              left: 'right',
            },
          ],
          yAxis: [
            {
              type: 'value',
              axisLine: {
                show:false,
                lineStyle: {
                  color: '#BFEDFF',
                },
              },
              min: this.min,
              max: this.max,
              axisTick: {
                show: false,
              },
              axisLabel: {
                color: '#BFEFFF',
                fontSize: this.YAreaFontSize,
              },
              //隔行换色
              splitArea: {
                show: false,
                areaStyle: {
                  color: ['transparent', 'rgba(238,238,238,.1)'],
                },
              },
              splitLine: {
                show: true,
                lineStyle:{
                    type:'solid'
                }
              },
            },
            {
              type: 'value',
              axisLine: {
                show: false,
              },
              min: this.mins,
              max: this.maxs,
              axisTick: {
                show: false,
              },
              axisLabel: {
                color: '#BFEDFF',
                fontSize: 12,
              },
              //隔行换色
              splitArea: {
                show: false,
                areaStyle: {
                  color: ['transparent', '#41bbdd'],
                },
              },
              splitLine: {
                show: false,
              },
            },
          ],
          // 数据过多时添加滚动条
          dataZoom: [
            {
              type: 'slider',
              show: this.zoomShow, //flase直接隐藏滚动条
              xAxisIndex: [0],
              left: this.leftwidth, //滚动条靠左侧的百分比
              bottom: -23,
              start: 0, //滚动条的起始位置
              end: this.endWeith, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
            },
          ],
          series: [],
        };
      }

      that.yData.forEach((d, index) => {
        let serie = {
          type: 'line',
          yAxisIndex: this.showRightYaxisLabel ? index : 0,
          data: d,
        };

        serie.itemStyle = {
          normal: {
            color: that.legends[index].color,
          },
        };

        if (that.displayModel == 1) {
          serie.areaStyle = {
            color: new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0.3,
                color: that.legends[index].color,
              },
              {
                offset: 1,
                color: that.legends[index].color + '00',
              },
            ]),
          };
        }

        serie.smooth = that.smooth;
        if (that.stack) {
          serie.stack = '0';
        }

        that.option.series.push(serie);

        if (
          that.symbolSizeIndex &&
          that.option.series.length > +that.symbolSizeIndex
        ) {
          // console.log(that.option.series.length, +that.symbolSizeIndex);
          that.option.series[+that.symbolSizeIndex].symbolSize = 0;
          that.option.series[+that.symbolSizeIndex].areaStyle = {
            color: new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0.3,
                color: 'rgba(58,77,233,0 )',
              },
              {
                offset: 1,
                color: 'rgba(58,77,233,0 )',
              },
            ]),
          };
        }
      });

      that.yDataRight.forEach((d, index) => {
        let serie = {
          type: 'line',
          data: d,
          yAxisIndex: 1,
        };
        serie.itemStyle = {
          normal: {
            color: that.legendsRight[index].color,
          },
        };

        if (that.displayModel == 1) {
          serie.areaStyle = {
            color: new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0.3,
                color: that.legendsRight[index].color,
              },
              {
                offset: 1,
                color: 'transparent',
              },
            ]),
          };
        }

        serie.smooth = that.smooth;
        if (that.stack) {
          serie.stack = '0';
        }
        that.option.series.push(serie);
      });
      return that.option;
    },
    tooltipShuffling() {
      clearInterval(this.setTime);
      let count = 0;
      let that = this;
      that.setTime = setInterval(() => {
        if (count == this.xData.length) {
          count = 0;
        }
        that.lineArea.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: count,
        });
        count++;
      }, 2000);
    },
    transformData(rowData) {
      let that = this;
      //获取类别去重
      let dimensions = Array.from(
        new Set(
          rowData.map((r) => {
            return r.dimension;
          })
        )
      );

      that.xData = dimensions;
      that.yData = [];

      let legends = Array.from(
        new Set(
          rowData.map((r) => {
            return r.legend;
          })
        )
      );

      legends.forEach((l) => {
        let array = [];
        dimensions.forEach((d) => {
          let data = rowData.find((r) => r.dimension == d && r.legend == l);
          array.push(data.measure);
        });
        that.yData.push(array);
      });
    },
    transformData1(rowDataRight) {
      let that = this;
      //获取类别去重
      let dimensions = Array.from(
        new Set(
          rowDataRight.map((r) => {
            return r.dimension;
          })
        )
      );

      that.xData = dimensions;
      that.yDataRight = [];

      let legends = Array.from(
        new Set(
          rowDataRight.map((r) => {
            return r.legend;
          })
        )
      );

      legends.forEach((l) => {
        let array = [];
        dimensions.forEach((d) => {
          let data = rowDataRight.find(
            (r) => r.dimension == d && r.legend == l
          );
          array.push(data.measure);
        });
        that.yDataRight.push(array);
      });
    },
  },
  watch: {
    immediate: true,
    rowData: {
      handler(neVal) {
        let that = this;
        that.transformData(neVal);
        that.option = that.initOption(that.option);
        that.yData.forEach((n, index) => {
          that.option.series[index].data = n;
        });
        this.$nextTick(() => {
          that.lineArea.setOption(that.option, true);
        });
      },
      deep: false,
    },
    rowDataRight: {
      handler(neVal) {
        let that = this;
        that.transformData1(neVal);
        that.option = that.initOption(that.option);
        that.yDataRight.forEach((n, index) => {
          that.option.series[index].data = n;
        });
        this.$nextTick(() => {
          that.lineArea.setOption(that.option, true);
        });
      },
      deep: false,
    },
  },
  destroyed() {
    this.setTime && clearInterval(this.setTime);
    this.lineArea = null;
    this.option = null;
  },
};
</script>
<style lang="less" scoped>
.lineArea {
  pointer-events: all;
  position: relative;
  padding-top: 10px;
  pointer-events: all;

  .legend {
    width: 100%;
    display: flex;
    position: absolute;
    top: 15px;
    right: 5%;
    justify-content: flex-end;
    line-height: 18px;

    .legendItem {
      padding-left: 10px;
      display: flex;

      .block {
        margin-top: 5px;
        width: 14px;
        height: 6px;
      }

      .vertical {
        width: 6px;
        height: 14px;
        margin-top: 0;
      }

      .text {
        color: #bfebff;
        font-size: 14px;
        padding-left: 5px;
      }
    }
  }
}
</style>
