<template>
  <div class="tableContent">
    <div class="table-content" :style="{ width: TbaleContentWidth }" @mouseenter="TableMouseOver"
      @mouseleave="TableMouseOut">
      <ul class="table-header" :style="{
        height: HeaderHeight,
        lineHeight: HeaderHeight,
        background: tableHeadersSetUp.HeaderBgColor,
        color: tableHeadersSetUp.HeaderFontColor,
      }">
        <li v-for="(item, index) in tableHeaders" :key="index" :style="{
          width: item.width,
          textAlign: item.align,
          borderRight: tableHeadersSetUp.HeaderBorder,
          fontSize: HeaderFontSize,
        }" :title="item.name">
          {{ item.name }}
        </li>
      </ul>
      <div class="content-body scroll" ref="scroll" :style="{ height: TableBodyHeight }">
        <div class="contScroll">
          <!-- :key 到时候需要修改放唯一ID  -->
          <div class="list" v-for="(val, ind) in TableData" :key="ind" :style="[
            tableBodySetup.TableIsBlank
              ? (ind + 1) % 2 == 0
                ? tableBodySetup.TableListEven
                : tableBodySetup.TableListOdd
              : tableBodySetup.TabListBg,
            val.__table_show_ ? tableBodySetup.HighlightStyle : '',
            {
              color: tableBodySetup.TableListColor,
              fontSize: TabliListFontSize,
              height: TableListHeight,
              lineHeight: TableListLineHeight,
            },
          ]">
            <ul>
              <li v-for="(item, ObjKey, index) in val" :key="index" :style="{ width: computeWidth(ObjKey) }">

                <div>
                  <span class="titleindex" v-if="index == 0 && isShowIndex"
                    :style="{ background: 'url(' + indexBak + ')no-repeat' }">
                    {{ ind >= 10 ? ind : `0${ind + 1}` }}</span>
                  <span :class="setItemColor(item)" :title="item">{{
                    item
                  }}</span>{{ val.__show }}
                </div>
                {{ val.__show }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonTable1',
  props: {
    //是否显示索引
    isShowIndex: {
      type: Boolean,
      default: false,
    },
    // 索引背景图
    indexBak: {
      type: String,
      default: () => {
        return '';
      },
    },
    // 是否开启颜色修改
    itemColorStart: {
      type: Boolean,
      default: false,
    },
    // 整体宽度
    TbaleContentWidth: {
      type: String,
      default: () => {
        return '542px';
      },
    },
    // 表格头部高度
    HeaderHeight: {
      type: String,
      default: () => {
        return '50px';
      },
    },
    // 表头文字大小
    HeaderFontSize: {
      type: String,
      default: () => {
        return '12px';
      },
    },
    //  表格容器
    TableBodyHeight: {
      type: String,
      default: () => {
        return '160px';
      },
    },
    //  单行表格行高
    TableListHeight: {
      type: String,
      default: () => {
        return '30px';
      },
    },
    // 单行表格高度
    TableListLineHeight: {
      type: String,
      default: () => {
        return '30px';
      },
    },
    // 表格文字大小
    TabliListFontSize: {
      type: String,
      default: () => {
        return '12px';
      },
    },
    // 表格是否超出滚动
    TableIsScroll: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    // 表格超过几个滚动
    TableScrollNum: {
      type: Number,
      default: () => {
        return 5;
      },
    },
    // 滚动间隔时间
    TableIsScrollTime: {
      type: Number,
      default: () => {
        return 3000;
      },
    },
    // 表格头数据
    /**
     *参数说明 固定格式
     *@constructor tableHeader
     *@author zhangheng
     *@param  {String} align 文字对齐方向默认向左
     *@param  {String} name 表头
     *@param  {String} width 宽度
     *@param  {String} key 表格内容的参数
     **/
    tableHeader: {
      type: Array,
      default: () => {
        return [
          { align: '', name: '车牌号', width: '24%', key: 'name' },
                    { align: '', name: '起点', width: '18%', key: 'from' },
                    { align: '', name: '终点', width: '18%', key: 'end' },
                    { align: '', name: '货物名称', width: '22%', key: 'goodname' },
                    { align: '', name: '当前状态', width: '18%', key: 'state' },
        ];
      },
    },
    // 数据
    /**
     *参数说明 参数以tableHeader   key  个数为准 最后data 为额外参数 如 id code ==
     *@constructor TableData
     *@author zhangheng
     *@param  {data} 额外参数
     **/
    TableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 表格是否可以点击
    TableIsClockHigh: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    /**
     *函数说明 函数名=>tableClick
     *@constructor 已经注册表格点击emit 事件
     *@author zhangheng
     *@param  {Obj} val  当前点击的Obj
     *@param  {Boolean} bool  当前点击是否高亮
     // 用法
     <组件 @tableClick="tableClick" />
     methods
     tableClick(val,bool) {
      console.log(val,bool)
    }
     **/
  },
  data() {
    return {
      // 表头内容
      tableHeadersSetUp: {
        // 表头背景颜色
        HeaderBgColor: '',
        // 表头文字颜色
        // HeaderFontColor: "#fff",
        HeaderFontColor: '#D5F7FD',
        // 表头边线颜色
        HeaderBorder: '1px solid #012437',
      },
      // 表格设置
      tableBodySetup: {
        // 是否隔行变色
        TableIsBlank: true,
        // 偶数样式
        TableListEven: {
          background: '',
        },
        // 奇数样式
        TableListOdd: {
          background: '#224F6A',
        },
        // 如果不开启隔行变色样式
        TabListBg: {
          background: '',
        },
        // 点击高亮样式
        HighlightStyle: {
          background: 'rgba(217,163,0,0.35)',
        },
        // 文字颜色
        TableListColor: '#D5F7FD',
      },
      // 滚动方法
      SetInte: null,
      // 判断鼠标离开是否高亮
      TableIsShow: false,
    };
  },
  computed: {
    TableDatas() {

    },
    tableHeaders: {
      get() {
        return this.tableHeader;
      },
      set() { },
    },
  },
  methods: {
    /**
     *函数说明
     *@constructor computeWidth
     *@author zhangheng
     *@param  {String}  Strkey - 参数名 参数说明
     *@return {String} 返回width
     **/
    computeWidth(Strkey) {
      let Str = '';
      for (let i = 0; i < this.tableHeaders.length; i++) {
        if (Strkey == this.tableHeaders[i].key) {
          Str = this.tableHeaders[i].width;
        }
      }
      return Str;
    },
    /**
     *函数说明 表格滚动
     *@constructor
     *@author zhangheng
     **/
    tableScroll() {
      let that = this;
      let Scroll = function () {
        // 几个以上开始滚动
        let TableScrollNum = that.TableScrollNum;
        // 每个列表的高度
        let ContThisHeight = parseInt(that.TableListHeight);
        // 列表的总高度
        let TableBodyHeight = parseInt(that.TableBodyHeight);
        // 容器
        let ContScroll = that.$refs.scroll;

        // 首先判断滚动开关开启
        // 并且判断表格个数是否大于超过TableScrollNum滚动的条件
        if (that.TableIsScroll && that.TableData.length > TableScrollNum) {
          // 容器的高度-可视高度 == scrollTop

          if (
            ContScroll.scrollHeight - TableBodyHeight ==
            ContScroll.scrollTop
          ) {
            ContScroll.scrollTo(0, 0);
          } else {
            ContScroll.scrollTo({
              top: ContScroll.scrollTop + ContThisHeight,
              left: 0,
              behavior: 'smooth',
            });
          }
        }
      };
      that.$set(that, 'SetInte', setInterval(Scroll, that.TableIsScrollTime));
    },
    /**
     *鼠标经过关闭滚动
     *@constructor TableMouseOver
     *@author zhangheng
     **/
    TableMouseOver() {
      if (!this.TableIsShow) {
        // console.log('鼠标经过');
        clearInterval(this.SetInte);
        this.$set(this, 'SetInte', null);
      }
    },
    /**
     *鼠标离开滚动
     *@constructor TableMouseOut
     *@author zhangheng
     **/
    TableMouseOut() {
      // 只有当前非高亮，鼠标离开才会开启定时器
      if (!this.TableIsShow) {
        // console.log('鼠标离开');
        this.tableScroll();
      }
    },
    /**
     *鼠标点击某一项
     *@constructor TableClick
     *@author zhangheng
     **/
    // TableClick(val, index) {
    //   // 开关=>点击是否高亮
    //   // console.log(val);
    //   if (this.TableIsClockHigh) {
    //     if (val.__table_show_) {
    //       // 取消高亮
    //       this.$set(val, '__table_show_', false);
    //       // 修改状态=>鼠标离开判断是否高亮
    //       this.$set(this, 'TableIsShow', false);
    //       // 取消高亮
    //       this.$emit('tableClick', val, false);
    //     } else {
    //       // 高亮
    //       for (let i = 0; i < this.TableDatas.length; i++) {
    //         this.$set(this.TableDatas[i], '__table_show_', false);
    //       }
    //       // 鼠标离开判断是否高亮
    //       this.$set(this, 'TableIsShow', true);
    //       clearInterval(this.SetInte);
    //       this.$set(this, 'SetInte', null);
    //       //修改状态=> 鼠标离开判断是否高亮
    //       this.$set(val, '__table_show_', true);
    //       //  $emit
    //       this.$emit('tableClick', val, true);
    //     }
    //     // 强制更新视图
    //     this.$forceUpdate();
    //   }
    // },

    // 根据状态修改颜色
    setItemColor(val) {
      if (this.itemColorStart) {
        switch (val) {
          case '已上线':
            return 'onLineColor';
          case '进行中':
            return 'conductColor';
          default:
            break;
        }
      }
    },
  },
  mounted() {
    // 注释：获取到数据以后需要在.ftl 里面 class='list' :key 更换成唯一ID
    let that = this;
    // 开启定时器
    that.tableScroll();
  },
  // 在页面销毁之前清除掉定时器
  beforeDestroy() {
    clearInterval(this.SetInte);
    this.$set(this, 'SetInte', null);
  },
};
</script>

<style scoped>
.table-content .table-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  width: 100%;
  /* text-align: left;
  padding: 0;
  margin: 0; */
}

.table-content .table-header li {
  /* padding: 0 10px 0 12px; */
  -webkit-box-sizing: border-box;
  /* box-sizing: border-box; */
  padding: 0 0 0 0;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border:unset !important;
}

.table-content .table-header li:last-child {
  border-right: none !important;
}

.table-content .content-body {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.table-content .content-body .contScroll {
  position: absolute;
  width: 100%;
  height: 100%;
}

.table-content .content-body .list {
  width: 100%;
}

.table-content .content-body .list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-align: center;
}

.table-content .content-body .list ul li {
  padding: 0 10px 0 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
table{
table-layout:fixed;
}
.table-content .content-body .list ul li .titleindex {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  font-family: 'Voltage';
  font-size: 26px;
  color: #fff;
}

.table-content .scroll::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 7px;
}

.table-content .scroll::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background: rgba(160, 160, 160, 0.6);
}

.table-content .scroll::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: rgba(255, 255, 255, 0);
}

.onLineColor {
  color: #4cffa5;
}

.conductColor {
  color: #eaf213;
}
</style>
